

.back-button
{
	position: absolute;
	left:6px;
	top:40vh;
	height: 20vh;
	
	//background: var(--c_1);
	z-index: 6;
	opacity: 0;
	transition: left 0.5s, opacity 1s, width 0.3s, background-color 1s;
	display: block;
	width: 0px;
	text-align: center;
	
}


@media only screen and (max-width: 800px)
{
	.back-button
	{
		left:-50px;
	}
}


.back-button.active
{
	opacity: 1;
	width: 4vh;
	max-width: 8vw;
	
}

.back-button img
{
	filter:brightness(10%);
}

.back-button:hover img
{
	filter:brightness(33%) sepia(100%) hue-rotate(-50deg) saturate(600%);
}