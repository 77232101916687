.background-animated-water-canvas
{
	width: 100%;
	height:100vh; // fallback
	height:calc(var(--vh, 1vh) * 100);
	color:#0cf;
	font-family: 'Courier New', monospace;
	
}

.background-animated-water
{
	width: 100%;
	height:calc(var(--vh, 1vh) * 100);
	color:#0cf;
	position:absolute;
	bottom:0;
	display: none;
	// font-family: 'Courier New', monospace;
}

.background-char
{
	//position: absolute;
	opacity: 0.1;
	content: '#';
}

.water-front
{
	//position: absolute;
	opacity: 1;
	content: '#';

}

.water-back
{
	//position: absolute;
	opacity: 0.5;
}

.background-char:hover
{
	color:white;
	background: white;
}

// .background-char + .water-front
// {
// 	color:white;
// 	transition: none;
// }

// .water-front + .background-char
// {
// 	opacity: 1;
// 	color:white;
// 	transition: none;

// }
