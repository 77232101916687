@import '../../Row.scss';

.link-row
{
	@extend .row;

	color:var(--c_d);

	justify-content: center;
	animation: slideInFromLeft 1s ease-out 0s;
	animation-fill-mode :backwards;
	user-select: none;
	transition: color 0s;

}

.link-row:hover
{
	color:var(--h_1)!important;
	transition: color 0s;
}

@keyframes glimmer {
	0% {
		transform: translate(-200%,-200%) rotate(45deg);
		opacity: 1;
	}
	100% {
		transform: translate(800%,-200%) rotate(45deg);
		opacity: 1;
	}
	// 50% {
	// 	opacity: 1;
	// }
	// // 0% {
	// // 	transform: translate(0);
	// // }
	// // 10% {
	// // 	transform: translate(0%,-100%);
	// // }
	// 100% {
	// 	transform: translate(-100%,100%) rotate(45deg);
	// 	opacity: 1;
	// }
}

.glimmer
{
	pointer-events: none;
	position: relative;
	left:0;
	bottom:0;
	width: 800%;
	height: 100%;
	animation: glimmer 20s linear;
	animation-iteration-count: infinite;
	background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 33%, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 35%, rgba(255,255,255,0.1) 35%, rgba(255,255,255,0.1) 38%, rgba(255,255,255,1) 39%, rgba(255,255,255,0.1) 40%, rgba(255,255,255,0.0) 100%);

}

.image-row-helper-img
{
	background: black;
	margin: 2.5vh;
	border-radius:16px;
	overflow:hidden;
	position:relative;
	
}

.image-row-helper
{
	transform: scale(0.8) translateX(-30vw) rotateY(45deg) rotateX(90deg);
	opacity: 0;
	transition: transform 1s, opacity 0.5s;
}

.image-row-helper.reverse 
{
	transform: scale(0.8) translateX(30vw) rotateY(-45deg) rotateX(90deg);
}

.is-inViewport.image-row-helper
{
	opacity: 1;
	transform: none;
}

.image-row-helper img
{	

}


// @keyframes slideInFromLeft {
// 	0% {
// 		transform: translateX(-20%);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: translateX(0);
// 		opacity: 1;
// 	}
// }

// @keyframes slideUp {
// 	0% {
// 		transform: translateY(10vh);
// 	}

// 	100% {
// 		transform: translateY(0%);
// 	}
// }

.intro-page
{
	overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.intro-page::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.web_intro
{
	//position: absolute;
	width: 100%;
	margin:auto;
	font-size: 9vmin;
	// background-color: var(--c_9);
	justify-content: center;
}

.web_intro a
{
	text-decoration: none;
	color:var(--c_d);
}

// .web_intro .row
// {
// 	justify-content: center;
// 	animation: slideInFromLeft 1s ease-out 0s;
// 	animation-fill-mode :backwards;
// 	user-select: none;
// 	transition: color 0s;
// }

.web_intro .row:hover
{
	color:var(--h_1);
	transition: color 0s;
}

// .web_intro div
// {
// 	animation-delay: 3s;
// 	animation-name: slideUp;
// 	animation-duration: 1s;
// 	animation-fill-mode :backwards;
// }

// .web_intro div .row:nth-child(1)
// {
// 	animation-delay: 0.5s;
// 	animation-name: slideInFromLeft;
// 	animation-duration: 1s;
// }

// .web_intro div .row:nth-child(2)
// {
// 	animation-delay: 1s;
// 	animation-name: slideInFromLeft;
// 	animation-duration: 1s;

// }

// .web_intro .row:nth-child(2)
// {
// 	animation-delay: 4s;
// }
// .web_intro .row:nth-child(3)
// {
// 	animation-delay: 4.5s;
// }

.intro_9_slice
{
	width: calc( 100% );
	height:100%;
	border-image-source:url(/Assets/Images/intro_card_9_hand_decorated.png);
	border-image-slice:33% fill;

	border-image-repeat: round;
	//border-image-repeat: space;
	border-image-width: 33px;
}

@media only screen and (max-width: 600px){
	.intro_9_slice
	{
		border-image-source:url(/Assets/Images/intro_card_9_hand_simple.png) !important;
	}
}

.intro_9_slice2
{
	width:100%;
	height:100%;
	border-image-source:url(/Assets/Images/intro_card_9.png);
	border-image-slice:34% fill;

	border-image-repeat: round;
	border-image-width: 33px;
}

.intro_9_slice3
{
	width:100%;
	height:100%;
	//border-image-source:url(/Assets/Images/intro_card_9_hand.png);
	border-image-slice:33% fill;

	border-image-repeat: round;
	border-image-width: 33px;
}