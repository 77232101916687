@keyframes scroll-hint-ball {
	0% {
		transform: translateY(200%);
		opacity: 0;
	}
	75% {
		transform: translateY(700%);
		opacity: 1;
	}
	100% {
		transform: translateY(700%);
		opacity: 0;
	}
}

.scroll-hint {
	position: relative;
	color: var(--c_f);
	height: 80px;
	width: 40px;
	max-height: 15vh;
	max-width: 7.5vh;
	border-radius: 20px;
	border: 2px solid var(--c_f);
}

.scroll-hint-knob {
	will-change: transform, opacity;
	border-radius: 4px;
	margin: auto;
	width: 8px;
	height: 8px;
	max-height: 1.5vh;
	max-width: 1.5vh;
	background: var(--c_f);
	animation-name: scroll-hint-ball;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
