@keyframes flicker {
	0%   {opacity: 1.0;}
	10%  {opacity: 1.0;}
	11%  {opacity: 0.6;}
	21%  {opacity: 0.6;}
	22%  {opacity: 1.0;}
	32%  {opacity: 1.0;}
	33%  {opacity: 0.7;}
	43%  {opacity: 0.7;}
	44%  {opacity: 1.0;}
	54%  {opacity: 1.0;}
	55%  {opacity: 0.4;}
	65%  {opacity: 0.4;}
	66%  {opacity: 1.0;}
	81%  {opacity: 1.0;}
	82%  {opacity: 0.7;}
	99%  {opacity: 0.7;}
	100% {opacity: 1.0;}
  }

.theme_swap
{
	justify-content: end;
	width: auto;
	//position:fixed;
	//right:0px;
	z-index: 3;
}

.theme_swap_swatch
{
	width:24px;
	height:20px;
	z-index: 1;
	text-align: center;

	

}

.theme_swap_swatch.dark:not(.active)
{
	filter:brightness(0%)
}



.theme_swap_swatch.dark:hover:not(.active)
{
	z-index: 0;

	filter: brightness(1.2)  drop-shadow(0px 0px 5px #5ab5ff) !important;
}

.theme_swap_swatch.light:hover:not(.active)
{
	z-index: 0;
	
	filter: brightness(1.2)  drop-shadow(0px 0px 5px #ffc55a)!important;

}

.theme_swap_swatch.light.active
{
	transition:filter 0.25s;
}

.theme_swap_swatch.dark.active
{
	transition:filter 1s;
}

.theme_swap_swatch.light
{
	height: 19px;// just looks more even if it's 1 px shorter, even if this makes no sense
}

.theme_swap_swatch.light:hover img,.theme_swap_swatch.light.active img
{
	filter: invert(42%) sepia(44%) saturate(3015%) hue-rotate(332deg) brightness(98%) contrast(116%)!important;
	transition:opacity 0.5s;
	animation-name: flicker;
	animation-duration: 3s;
	animation-iteration-count: infinite;
}

.theme_swap_swatch.dark:hover img,.theme_swap_swatch.dark.active img
{
	filter: invert(42%) sepia(44%) saturate(3015%) hue-rotate(180deg) brightness(98%) contrast(116%)!important;
	transition:opacity 1s;
}