.language_swap
{
	justify-content: end;
	width: auto;
	//position:fixed;
	//left:0px;
	z-index: 3;
	user-select: none;
}

.language_swap_swatch
{
	width:40px;
	height:20px;
	z-index: 1;
	text-align: center;
	

}

.language_swap span
{
	transition: color 0.3s;
	
}

.language_swap span.active
{
	color:red
}

.language_swap span:hover:not(.active)
{

	color:white !important;
}
