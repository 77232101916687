@import "../constants.scss";

#fullscreen_gallery .gallery_nav_button
{
	color:#ddd;
	
}

#fullscreen_gallery .gallery_nav_button:hover
{
	color: var(--h_1);
	filter: sepia(100%) hue-rotate(300deg) saturate(50000%) brightness(100%);
}


.fullscreen_gallery_img.clickable:hover
{
	border: 4px solid gray;
	border-radius: 4px;
	box-shadow: 0 0 8px;
	z-index: 7;
}


.fullscreen_gallery_img
{
	position: absolute;
	transition: transform 1s, width 1s, height 1s;
}