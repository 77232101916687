
#grid_test
{
	position:relative;
	display:grid;
	grid-template-columns:auto auto;
	gap:calc(1vw + 10px);
}

.grid_box
{
	display:inline;
	border:1px solid var(--c_2);
	width:min(30vh,30vw);
	height:min(30vh,30vw);
	min-width: 300px;
	min-height: 300px;
}

.grid_box_small
{
	border:1px solid var(--c_2);
}

.grid_box_hover
{
	position: relative;
}

.grid_box_hover:hover
{
 	/* filter: brightness(calc(140% - var(--theme) * 0.7)) saturate(220%); */
	border-width: 8px;
}

.grid_box_label
{
	
	position: absolute;
	height: 0;
	background-color: var(--c_0);
	width: calc(100% + 16px);
	font-size: calc(max(20px,2vh));
	bottom:-8px;
	left:-8px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	
}

.grid_box_hover:hover .grid_box_label
{
	height:calc(max(20px,2vh) + 16px);
	border:1px solid var(--c_3);
	
}


@media (max-width: 800px) 
{
	#grid_test {
	  grid-template-columns: auto;
	}

	#grid_test>div
	{
		width: 60vw;
		height: 60vw;
	}
}

#grid_test div img
{
	height:  100%;
	/* filter: invert(calc(100% - var(--theme))); */
	transition: filter 1s;
}