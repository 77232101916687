@import "../constants.scss";

.row
{
  padding: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}