$intro-card-height : calc(min(80vh, 400px));

$duration : 2s;


@keyframes card-turn {
  from {
    transform: rotate3d(1,0,0,0deg);
  }
  100% {
    transform: rotate3d(0.5,1,0,360deg);
  }
}

@keyframes card-turn-backside {
  from {
    transform: rotate3d(1,0,0,0deg) translateZ(-20px);
  }
  100% {
    transform: rotate3d(0.5,1,0,360deg) translateZ(-20px);
  }
}

@keyframes card-turn-left {
  from {
    transform: rotate3d(1,0,0,0deg) translateZ(-10px) translateX(-120px) rotateY(90deg) scaleX(0.08333);// translateZ(-10px);// rotate3d(1,0,0,0deg)
  }
  100% {
    transform: rotate3d(0.5,1,0,360deg) translateZ(-10px) translateX(-120px) rotateY(90deg) scaleX(0.08333);//translateX(-120px) rotate3d(0.5,1,0,360deg)
  }
}

@keyframes card-turn-right {
  from {
    transform: rotate3d(1,0,0,0deg) translateZ(-10px) translateX(120px) rotateY(90deg) scaleX(0.08333);// translateZ(-10px);// rotate3d(1,0,0,0deg)
  }
  100% {
    transform: rotate3d(0.5,1,0,360deg) translateZ(-10px) translateX(120px) rotateY(90deg) scaleX(0.08333);//translateX(-120px) rotate3d(0.5,1,0,360deg)
  }
}

@keyframes card-turn-top {
  from {
    transform: rotate3d(1,0,0,0deg) translateZ(-10px) translateY(-200px) rotateX(90deg) scaleY(0.05);// translateZ(-10px);// rotate3d(1,0,0,0deg)
  }
  100% {
    transform: rotate3d(0.5,1,0,360deg) translateZ(-10px) translateY(-200px) rotateX(90deg) scaleY(0.05);//translateX(-120px) rotate3d(0.5,1,0,360deg)
  }
}

@keyframes card-turn-bottom {
  from {
    transform: rotate3d(1,0,0,0deg) translateZ(-10px) translateY(-200px) rotateX(90deg) scaleY(0.05);// translateZ(-10px);// rotate3d(1,0,0,0deg)
  }
  100% {
    transform: rotate3d(0.5,1,0,360deg) translateZ(-10px) translateY(-200px) rotateX(90deg) scaleY(0.05);//translateX(-120px) rotate3d(0.5,1,0,360deg)
  }
}

@keyframes card-size {
  from {
	  opacity: 0;
    transform: translateY(180px);
  }
  to{
	  opacity: 1;
    transform: translateY(0%);
  }
}



@keyframes card-turn-name {
  from {
    opacity: 1;
  }
  // 3.5%{
  //   opacity: 1;
  // }
  // 3.6%{
  //   opacity: 0;
  // }
  // 9% {
  //   opacity: 0;
  // }
  // 9.1% {
  //   opacity: 1;
  // }
  100% {
    opacity: 1;
  }
}

@keyframes card-turn-title {
  from {
    opacity: 0;
  }
  44.9% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.intro-card
{
  transform-style: preserve-3d;
  position: absolute;
  top:calc(50% - #{$intro-card-height} / 2 );
  left:calc(50vw - 120px);
  animation: card-size 1s;
  animation-delay: 0s;
  //overflow: hidden;
  height: $intro-card-height; 
  width: 240px; 
  border-radius: 16px;
  padding:1px;
  box-sizing: border-box;
}

#intro-card-front
{
  z-index: 1;
  animation: card-turn $duration forwards;
  animation-delay: 1.5s;
  background: black;
  height: 100%;
  width: 100%;
  border: 2px solid white;
  border-radius: 16px;
  padding:16px;
  position: absolute;
  left: 0;
  top: 0;
}

#intro-card-back
{
  z-index: 0;
  animation: card-turn-backside $duration forwards;
  animation-delay: 1.5s;
  background: black;
  height: 100%;
  width: 100%;
  border: 2px solid white;
  border-radius: 16px;
  padding:16px;
  position: absolute;
  left: 0;
  top: 0;
}

#intro-card-left
{
  z-index: 0;
  animation: card-turn-left $duration both;
  animation-delay: 1.5s;
  background: black;
  height: calc(100% - 32px);
  width: 100%;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding:16px;
  position: absolute;
  left: 0px;
  top: 16px;
}

#intro-card-right
{
  z-index: 0;
  animation: card-turn-right $duration both;
  animation-delay: 1.5s;
  background: black;
  height: calc(100% - 32px);
  width: 100%;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding:16px;
  position: absolute;
  left: 0px;
  top: 16px;
}

#intro-card-top
{
  z-index: 0;
  animation: card-turn-top $duration both;
  animation-delay: 1.5s;
  background: black;
  height: 100%;
  width: calc(100% - 32px);
  border-left: 2px solid white;
  border-right: 2px solid white;
  padding:16px;
  position: absolute;
  left: 16px;
  top: 0px;
}

#intro-card-bottom
{
  z-index: 0;
  animation: card-turn-bottom $duration both;
  animation-delay: 1.5s;
  background: black;
  height: 100%;
  width: calc(100% - 32px);
  border-left: 2px solid white;
  border-right: 2px solid white;
  padding:16px;
  position: absolute;
  left: 16px;
  top: 0px;
}

#title-sven
{
  animation: card-turn-name $duration both;
  animation-delay: 1.5s;
}

#title-about,#title-projects
{
  animation: card-turn-title $duration both;
  animation-delay: 1.5s;
}

.intro-card a
{
  color:white;
  text-align: center;
  width: 100%;
  font-size: 22px;
}