@import "../constants.scss";

.gallery_container
{
    min-width: calc( min(600px,50%));
    max-width: 100%;
	min-height: 400px;
	display: flex;
	margin:16px;
	//border: 1px solid white;
}

.gallery_img
{
	position: absolute;
	object-fit: contain;
	width: 100%;
	max-height: 100%;
	transition: transform 1s;
}

.gallery_nav_button
{
	position: absolute;
	flex:1;
	font-size:80px;
	user-select: none;
	transition:opacity 0s;
	padding:50% 36px;
	
}

#fullscreen_gallery .gallery_nav_button
{
	color:#ddd
}

#fullscreen_gallery .gallery_nav_button:hover
{
	color: var(--h_1);
}

.gallery_nav_button:hover
{
	color: var(--h_1);
	
}

