@import "../constants.scss";

:root
{
	--theme:0%;
	// Basic colors
	--c_0:#000;
	--c_05:#0a0a0a;
	--c_1:#111;
	--c_15:#181818;
	--c_2:#222;
	--c_3:#333;
	--c_4:#444;
	--c_5:#555;
	--c_6:#666;
	--c_7:#777;
	--c_8:#888;
	--c_9:#999;
	--c_a:#aaa;
	--c_b:#bbb;
	--c_c:#ccc;
	--c_d:#ddd;
	--c_e:#eee;
	--c_f:#fff;
	--text_opacity:1;
	--background:#0a0a0a;
	--panel:#111;

	// Highlights
	--h_1:#f44;
	--h_bad:#f88;
	--h_good:#8af;
}

body
{
  scrollbar-color:#333 #1f1f1f ;
	overflow: clip;
	position:fixed;
    top:0;
    bottom: 0;
	font-size: calc(min(max( 5vw, 12px ), 16px));
}

// ::-webkit-scrollbar {
//   max-width: 6px;
// }

// ::-moz-scrollbar {
//   width: 100px;
// }


.App {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  background: $background;
  color:$highlight2;
  display: flex;
  flex-direction: column;

  overflow: clip;
	position:fixed;
  top:0;
  bottom: 0;

}

#root
{
  width: 100%;
  height: 100%;
  overflow: clip;
  position: fixed;
}

.App button
{
  background-color: $background_highlighted2;
  color:white;
  padding:6px 12px;
  border-color: $background;
  border-radius: 4px;
}

.App button:hover
{
  background-color: $background_highlighted;
}

.App button:active
{
  background-color: $background;
}

.split 
{
	display: flex;
	width: 100%;
	position: relative;
	flex-grow: 1;
}


.gutter
{
  background-color: $separator;
}

.gutter-vertical:hover
{
  cursor: row-resize;
}

.gutter-horizontal:hover
{
  cursor: col-resize;
}




.text_editor_wrapper
{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.text_editor
{
  flex-grow: 1;
}


.text_editor_toolbar
{
  background-color: $background!important;
  border-color: $separator!important;
}

.rdw-option-wrapper
{
  background-color: $background_highlighted!important;
  border-color: $highlight!important;
}

.rdw-option-wrapper img
{
  filter:contrast(20%) brightness(200%)
}

.rdw-dropdown-wrapper
{
  background-color: $background_highlighted!important;
  border-color: $highlight!important;
  color:$highlight2!important;
}

.rdw-emoji-modal
{
  background-color: $background_highlighted!important;
  border-color: $highlight!important;
  box-shadow: none!important;
}

h3,span
{
	text-align: center;
}