@keyframes slide-up {
	0%{transform: translateY(100%)}
	100%{transform: translateY(0%)}
}

@keyframes slide-down {
	0%{transform: translateY(-100%)}
	100%{transform: translateY(0%)}
}

@keyframes slide-left {
	0%{transform: translateX(-100%)}
	100%{transform: translateX(0%)}
}

@keyframes slide-right {
	0%{transform: translateX(100%)}
	100%{transform: translateX(0%)}
}

// Reverse itself does not rerun the animation if changed, so we need separate animations for reverse

@keyframes slide-up-reverse {
	0%{transform: translateY(0%)}
	100%{transform: translateY(100%)}
}

@keyframes slide-down-reverse {
	0%{transform: translateY(0%)}
	100%{transform: translateY(-100%)}
}

@keyframes slide-left-reverse {
	0%{transform: translateX(0%)}
	100%{transform: translateX(-100%)}
}

@keyframes slide-right-reverse {
	0%{transform: translateX(0%)}
	100%{transform: translateX(100%)}
}

.page {
	will-change: transform, opacity;
	position: absolute;
	top: 28px;
	width: 100%;
	//min-height: 100%;
	//background: var(--c_05);//linear-gradient(to bottom, var(--c_1) 0%, var(--c_0) 70%, var(--c_0) 80%, var(--c_05) 100%);//
	transition: transform 0.5s, border-color 0.5s, background-color 1s;
	//border-left: 10px solid var(--c_1);
	display: flex;
	flex-direction: column;
	//justify-content: center;
	align-items: center;
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100% - 28px);
	//visibility: hidden;
	z-index: 2;
}

.page.inactive.up
{
	animation: slide-up 1s linear reverse both;
	//animation-fill-mode: both;
	//transform: translateY(100%);
}
.page.inactive.down
{
	animation: slide-down 1s linear reverse both;
	// transform: translateY(-100%);
}
.page.inactive.left
{
	animation: slide-left 1s linear reverse both;
	// transform: translateX(-100%);
}
.page.inactive.right
{
	animation: slide-right 1s linear reverse both;
	// transform: translateX(100%);
}

.page.active.up
{
	animation: slide-down 1s linear normal both;
}
.page.active.down
{
	animation: slide-up 1s linear normal both;
}
.page.active.left
{
	animation: slide-right 1s linear normal both;
}
.page.active.right
{
	animation: slide-left 1s linear normal both;
}

.page.active {
	//transform: translateX(0%);
	transition: transform 0.5s, border-color 0.5s, background-color 1s;
	z-index: 5;
	border-color: var(--c_05);
	visibility: initial;
	//background: var(--background);;
}

.page>div
{
	// filter: drop-shadow(3px 6px 1px #0002); // Don't do this! super expensive when combined with animations or transitions
}

.page_content {
	background: var(--panel);
	border-radius: 4px;
	//min-height:40%;
	flex-shrink: 1;
	height: auto;
	//max-height: calc(100vh - min(80vh, 64px));
	//overflow-y: auto;
	//height: 80%;
	//width: 80%;
	max-width: calc(100vw - 1.2 * 8vh - 16px);//calc(100vw - min(16vw, 128px) - 16px);
	padding: 16px;
	// margin-top: 48px;
	// margin: 32px calc(min(8vw,50px));
	transition: background-color 1s, max-width 0.5s;
}

@media only screen and (max-width: 800px) {
	.page_content {
		max-width: calc(100% + 10px);
	}
}

.card
{
	outline: 2px solid #ffffff0f;
	background: #ffffff03;
	border-radius: 8px;
	margin:8px;
	padding:8px;
	text-align: left;
}