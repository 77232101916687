body 
{
	background-color: var(--background);
	color: var(--c_d);
	transition: background-color 1s, color 1s;
}

body * 
{
	transition: background-color 1s, color 1s;
}

.web 
{
	overflow: clip;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	background: var(--background);
	color: var(--c_d);
}

a 
{
	color: var(--h_bad);
	transition: color 0s;
}

a:visited 
{
	color: var(--h_bad);
}

a:hover 
{
	color: var(--h_1);
}

#header 
{
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	height: 28px;
	align-items: center;
	border-bottom: 1px solid #222;
	background: var(--c_05);
	z-index: 1;
}


#page_container 
{
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#impressum_link {
	text-decoration: underline black;
	user-select: none;
	color: var(--c_d);
}

#impressum_link:hover
{
	text-decoration: underline black;
	user-select: none;
	color: var(--h_1);
}

.clickable:hover
{
	cursor: pointer;
}

.hoverable:hover
{
	filter: brightness(115%);
}
