@import "../constants.scss";

.responsive-row-column
	{
	padding: 4px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	}



@media only screen and (max-width: 800px)
{
	.responsive-row-column
	{
		width:100%;
		display: flex;
		flex-direction: column;
		position: relative;
	}
}
